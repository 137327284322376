<template>
  <el-aside width="200px" style="background-color: rgb(238, 241, 246)">
    <el-menu :router="true" :default-active="$route.path">
      <div v-if="authRole === 'admin'">
        <el-menu-item index="/dashboard">
          <i class="el-icon-house"></i>Dashboard
        </el-menu-item>

        <el-submenu index="1">
          <template slot="title"><i class="el-icon-suitcase"></i>Master Data</template>

          <!-- <el-menu-item-group> -->
          <!-- <template slot="title">Barang</template> -->
          <!-- <el-menu-item index="/barang-master">Barang Master</el-menu-item> -->
          <!-- <el-menu-item index="/barang-harga">Barang Harga</el-menu-item> -->
          <!-- <el-menu-item index="/barang-satuan">Barang Satuan</el-menu-item> -->
          <!-- <el-menu-item index="#">Barang Stok</el-menu-item> -->

          <!-- </el-menu-item-group> -->

          <!-- <el-submenu index="1-1">
          <template slot="title">Barang</template>
          <el-menu-item index="/barang-master">Index</el-menu-item>
          <el-menu-item index="/barang-master/create/">Create</el-menu-item>
        </el-submenu> -->

          <el-menu-item index="/barang-master">Barang</el-menu-item>
          <el-menu-item index="/barang-satuan">Satuan</el-menu-item>
          <el-menu-item index="/barang-stok">Stok</el-menu-item>

          <!-- <el-submenu index="1-2">
          <template slot="title">Satuan</template>
          <el-menu-item index="/barang-satuan/">Index</el-menu-item>
          <el-menu-item index="/barang-satuan/create/">Create</el-menu-item>
        </el-submenu> -->

          <!-- coming soon -->
          <!-- <el-submenu index="1-3">
          <template slot="title">Pelanggan</template>
          <el-menu-item index="/pelanggan/">Index</el-menu-item>
          <el-menu-item index="/pelanggan/create/">Create</el-menu-item>
        </el-submenu>

        <el-submenu index="1-4">
          <template slot="title">Supplier</template>
          <el-menu-item index="/supplier">Index</el-menu-item>
          <el-menu-item index="/supplier/create/">Create</el-menu-item>
        </el-submenu> -->

        </el-submenu>

        <el-submenu index="2">
          <template slot="title"><i class="el-icon-files"></i>Inventory</template>
          <!-- <el-menu-item index="/penyesuaian">Penyesuaian</el-menu-item> -->
          <el-menu-item index="/stok-masuk">Stok Masuk</el-menu-item>
          <el-menu-item index="/stok-keluar">Stok Keluar</el-menu-item>
        </el-submenu>

        <el-submenu index="3">
          <template slot="title"><i class="el-icon-money"></i>Pricelist</template>
          <el-menu-item index="/barang-harga">Harga Barang</el-menu-item>
        </el-submenu>

        <!--<el-submenu index="3">
          <template slot="title"><i class="el-icon-money"></i>Transaksi</template>
          <el-menu-item index="#">Order Customer</el-menu-item>
          <el-menu-item index="#">Surat Jalan</el-menu-item>
        </el-submenu>

        <el-submenu index="4">
          <template slot="title"><i class="el-icon-document"></i>Reporting</template>
          <el-menu-item index="#">Laporan PO</el-menu-item>
          <el-menu-item index="#">Order</el-menu-item>
          <el-menu-item index="#">Stok</el-menu-item>
        </el-submenu> -->

        <!-- end-menu -->
        <!-- <el-submenu index="2">
        <template slot="title"><i class="el-icon-menu"></i>Harga</template>
        
        <el-menu-item-group>
          <template slot="title">Harga</template>
          <el-menu-item index="/harga-barang-modal">Harga Barang Modal</el-menu-item>
        </el-menu-item-group>

      </el-submenu> -->

        <!-- <el-menu-item index="/metode-pembayaran">
        <i class="el-icon-money"></i>Metode Pembayaran
      </el-menu-item> -->

        <!-- <el-menu-item index="/pembayaran">
        <i class="el-icon-document"></i>Pembayaran
      </el-menu-item> -->
        

        <!-- <el-menu-item index="/list-barang">
          <i class="el-icon-box"></i>List Barang
        </el-menu-item>

        <el-menu-item index="/barang-harga">
          <i class="el-icon-price-tag"></i>Harga Barang
        </el-menu-item> -->

        <!-- <el-menu-item index="#">
        <i class="el-icon-truck"></i>Pengiriman
      </el-menu-item> -->

        <!-- <el-submenu index="3">
        <template slot="title"><i class="el-icon-shopping-cart-2"></i>Order</template>

        <el-menu-item-group>
          <template slot="title">Order</template>
          <el-menu-item index="/order">Order Main</el-menu-item>
          <el-menu-item index="/order-detail">Order Detail</el-menu-item>
        </el-menu-item-group>

      </el-submenu> -->

        <!-- <el-menu-item index="/setting">
          <i class="el-icon-tickets"></i>Invoice
        </el-menu-item> -->

        <!-- <el-menu-item index="/setting">
          <i class="el-icon-setting"></i>Setting
        </el-menu-item> -->

        <el-menu-item @click="logout">
          <i class="el-icon-switch-button"></i>Logout
        </el-menu-item>

        <!-- <el-submenu index="3">
        <template slot="title"><i class="el-icon-setting"></i>Navigator Three</template>
        <el-menu-item-group>
          <template slot="title">Group 1</template>
          <el-menu-item index="3-1">Option 1</el-menu-item>
          <el-menu-item index="3-2">Option 2</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group title="Group 2">
          <el-menu-item index="3-3">Option 3</el-menu-item>
        </el-menu-item-group>
        <el-submenu index="3-4">
          <template slot="title">Option 4</template>
          <el-menu-item index="3-4-1">Option 4-1</el-menu-item>
        </el-submenu>
      </el-submenu> -->
      </div>

      <div v-if="authRole === 'staff'">
        <el-menu-item index="/dashboard">
          <i class="el-icon-house"></i>Dashboard
        </el-menu-item>

        <el-menu-item index="/stok-masuk">
          <i class="el-icon-document-add"></i>Stok Masuk
        </el-menu-item>

        <el-menu-item index="/stok-masuk/create">
          <i class="el-icon-plus"></i>Buat Stok Masuk
        </el-menu-item>

        <el-menu-item index="/stok-keluar">
          <i class="el-icon-document-remove"></i>Stok Keluar
        </el-menu-item>

        <el-menu-item index="/stok-keluar/create">
          <i class="el-icon-minus"></i>Buat Stok Keluar
        </el-menu-item>

        <el-menu-item @click="logout">
          <i class="el-icon-switch-button"></i>Logout
        </el-menu-item>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
  export default {
    name: 'AsideMenu',
    data() {
      return {
        authRole: sessionStorage.getItem('authRole') || null
      };
    },
    methods: {
      logout() {
        // Clear the token from sessionStorage
        sessionStorage.removeItem('authToken');
        // Optionally, clear other user-related data (e.g., user info, preferences)
        this.$router.push({
          path: '/logout/process'
        });
        // Optional: If you want to reload the page after logout
        // location.reload();
        // this.$router.push({ name: 'Login'});
      }
    }
  };
</script>

<style></style>